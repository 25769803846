const markets = require('./markets.json')

function availableMarkets() {
  return markets
}

function isIndexedLanguage(market, language) {
  return market.indexedLanguages.some((lang) => lang.code === language.code)
}

module.exports = {
  availableMarkets,
  isIndexedLanguage,
}
