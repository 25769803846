import React from 'react'
import type { PublicCmsItem } from 'apollo-gql'
import logApolloError from 'lib/logApolloError'

import CmsItemBasic from 'components/CmsItem/CmsItemBasic'
import Spinner from 'components/Spinner/Spinner'
import usePage from 'hooks/usePage'

import Seo from './Seo'

interface CmsPageBasicProps {
  slug: string
  wrapItems?: boolean
}

const CmsPageBasic: React.FC<CmsPageBasicProps> = ({
  slug,
  wrapItems = false,
}) => {
  const { page, error, loading } = usePage(slug)

  if (error) {
    logApolloError(
      `[ContentLoad Error] Error loading content for page: ${slug}`,
      error,
    )
  }

  if (loading) return <Spinner height={30} centered />

  const cmsItems = page?.publicCmsItems || []
  const pageMetadata = page?.cmsItemPageMetadata

  return (
    <>
      <Seo pageMetadata={pageMetadata} />
      {cmsItems.map((cmsItem: PublicCmsItem, index) => (
        <CmsItemBasic
          indexPositionFromTop={index}
          key={cmsItem.id}
          item={cmsItem}
          wrapItems={wrapItems}
        />
      ))}
    </>
  )
}

export default CmsPageBasic
