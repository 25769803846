import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import allFeatureFlagsFragment from 'graphql/fragments/allFeatureFlagsFragment'
import fullPageFragment from 'graphql/fragments/fullPageFragment'
import sideMenuFragment from 'graphql/fragments/sideMenuFragment'
import { currentLanguageIdVar, currentMarketIdVar } from 'graphql/reactive'

const PREFETCH_PAGE = gql`
  query PrefetchPage(
    $marketId: ID!
    $languageId: String!
    $slug: String!
    $profilesPerCollection: Int! = 7
  ) {
    market(id: $marketId) {
      id
      page(languageId: $languageId, slug: $slug) {
        ...fullPageFragment
      }
      ...sideMenuFragment
    }
    ...allFeatureFlagsFragment
  }

  ${fullPageFragment}
  ${sideMenuFragment}
  ${allFeatureFlagsFragment}
`

async function prefetchCmsPage(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  variables: { slug: string | string[] },
): Promise<boolean> {
  const marketId = currentMarketIdVar()
  const languageId = currentLanguageIdVar()

  if (marketId && languageId) {
    await apolloClient.query({
      query: PREFETCH_PAGE,
      variables: {
        marketId,
        languageId,
        ...variables,
      },
    })
  }
  return true
}

export default prefetchCmsPage
