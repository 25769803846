/* eslint-disable react/no-danger */
import React from 'react'
import { useMarketContext } from 'contexts/MarketContext'
import prefetchCmsPage from 'graphql/prefetchers/prefetchCmsPage'
import { initializeApollo } from 'lib/apolloClient'
import { setCacheControlHeader } from 'lib/cache'
import { useTranslation, withTranslation } from 'lib/i18n'
import { trackLandingPageViewed } from 'lib/tracking/avo/trackPageView'
import { isServer } from 'lib/utils'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { NextSeo } from 'next-seo'

import CmsPageBasic from 'components/CmsPage/CmsPageBasic'
import PublicLayout from 'components/PublicLayout/PublicLayout'
import StartPageHero from 'components/StartPageHero/StartPageHero'
import useEffectOnce from 'hooks/useEffectOnce'
import useIsIndexedLanguage from 'hooks/useIsIndexedLanguage'
import usePath from 'hooks/usePath'

const NewsletterPopup = dynamic(() =>
  import('components/NewsletterPopup/NewsletterPopup'),
)

const HeaderVideoStartpage = dynamic(() =>
  import('components/HeaderVideoStartpage/HeaderVideoStartpage'),
)

const SLUG = 'home'

const Seo = () => {
  const { t } = useTranslation()
  const { localizedBaseUrl } = usePath()
  const { market } = useMarketContext()
  const { selectedMarket } = market
  const isIndexedLanguage = useIsIndexedLanguage()

  const website = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: localizedBaseUrl,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${localizedBaseUrl}/search?q={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  }

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(website) }}
        />
      </Head>
      <NextSeo
        title={t(
          'market.meta.title',
          'Personalised video messages from thousands of celebrities - memmo - {{localMarketName}}',
          { localMarketName: selectedMarket.localName },
        )}
        noindex={!isIndexedLanguage}
        nofollow={!isIndexedLanguage}
      />
    </>
  )
}

const IndexPage = () => {
  const path = usePath()

  useEffectOnce(() => {
    trackLandingPageViewed({ pageSlug: SLUG, path })
  })

  return (
    <>
      <Seo />
      <PublicLayout
        headerContent={<StartPageHero />}
        headerVideo={<HeaderVideoStartpage />}
      >
        <CmsPageBasic slug={SLUG} wrapItems />
        <NewsletterPopup />
      </PublicLayout>
    </>
  )
}

IndexPage.getInitialProps = async (ctx) => {
  if (isServer()) {
    setCacheControlHeader({ ctx, ttl: 900 })
    const apolloClient = initializeApollo(ctx)

    await prefetchCmsPage(apolloClient, { slug: SLUG })

    return {
      initialApolloState: apolloClient.cache.extract(),
    }
  }
  return {}
}

export default withTranslation('common')(IndexPage)
