import { NextPageContext } from 'next'

type CacheControlHeaderProps = {
  ctx: NextPageContext
  ttl?: number
}

const setCacheControlHeader = ({
  ctx,
  ttl = 300,
}: CacheControlHeaderProps): void => {
  ctx.res?.setHeader('Cache-Control', `public, s-maxage=${ttl}`)
}

export { setCacheControlHeader as default, setCacheControlHeader }
