import { ApolloError } from '@apollo/client'
import { logger } from 'lib/logger'

const logApolloError = (message: string, error: ApolloError): void => {
  if (error.networkError?.message === 'Observable cancelled prematurely') return

  logger.error(message, {
    metaData: {
      ApolloError: { ...error },
    },
  })
}

export default logApolloError
