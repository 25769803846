import type { PublicProfileCollection } from 'apollo-gql'

import {
  CmsPageViewedProperties,
  LandingPageViewedProperties,
  ProfileCollectionPageViewedProperties,
} from './sdk'
import getMarketTrackingProperties from './utils/getMarketTrackingProperties'

type PageViewParameters = {
  pageSlug: string
  path: {
    asPath: string
  }
  profileCollection?: PublicProfileCollection
}

const buildTrackEvent = (
  parameters: PageViewParameters,
):
  | CmsPageViewedProperties
  | LandingPageViewedProperties
  | ProfileCollectionPageViewedProperties => {
  const { pageSlug, path } = parameters
  return {
    ...getMarketTrackingProperties(),
    pageSlug,
    path: path.asPath,
  }
}

export const trackCmsPageViewed = async (
  parameters: PageViewParameters,
): Promise<void> => {
  const { cmsPageViewed } = await import('./sdk')
  cmsPageViewed(buildTrackEvent(parameters))
}

export const trackLandingPageViewed = async (
  parameters: PageViewParameters,
): Promise<void> => {
  const { landingPageViewed } = await import('./sdk')
  landingPageViewed(buildTrackEvent(parameters))
}

export const trackProfileCollectionPageViewed = async (
  parameters: PageViewParameters,
): Promise<void> => {
  const { profileCollection } = parameters
  if (!profileCollection) return
  const eventProperties = buildTrackEvent(parameters)

  const { profileCollectionPageViewed } = await import('./sdk')
  profileCollectionPageViewed({
    ...eventProperties,
    profileCollectionId: profileCollection.id,
    profileCollectionSlug: profileCollection.slug,
    profileCollectionProfileCount:
      profileCollection.profileProducts?.totalCount,
    profileCollectionType: profileCollection.collectionType,
  })
}
