import {
  currentLanguageCodeVar,
  currentLanguageIdVar,
  currentMarketIdVar,
} from 'graphql/reactive'
import { isIndexedLanguage } from 'lib/markets'

import useSelectMarket from 'hooks/useSelectMarket'

export default function useIsIndexedLanguage(): boolean {
  const currentMarketId = currentMarketIdVar()
  const currentLanguageId = currentLanguageIdVar()
  const currentLanguageCode = currentLanguageCodeVar()
  const selectMarket = useSelectMarket()
  const market = selectMarket({ marketId: currentMarketId })

  if (market.indexedLanguages?.length > 0) {
    return isIndexedLanguage(market, { code: currentLanguageCode })
  }
  return currentLanguageId === market.selectedMarket?.defaultLanguage.id
}
