import { currentLanguageIdVar, currentMarketIdVar } from 'graphql/reactive'

import useSelectMarket from 'hooks/useSelectMarket'

export default function useIsDefaultLanguage() {
  const currentMarketId = currentMarketIdVar()
  const currentLanguageId = currentLanguageIdVar()

  const selectMarket = useSelectMarket()
  const market = selectMarket({ marketId: currentMarketId })

  return currentLanguageId === market.selectedMarket?.defaultLanguage.id
}
