import { gql } from '@apollo/client'

import fullCmsItemFragment from './fullCmsItemFragment'

const sideMenuFragment = gql`
  fragment sideMenuFragment on Market {
    sideMenu {
      cmsItems {
        ...fullCmsItemFragment
      }
    }
  }
  ${fullCmsItemFragment}
`

export default sideMenuFragment
