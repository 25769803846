import React from 'react'
import type { PageQuery } from 'apollo-gql'
import { useTranslation } from 'lib/i18n'
import { NextSeo } from 'next-seo'

import useIsDefaultLanguage from 'hooks/useIsDefaultLanguage'

type SeoProps = {
  pageMetadata?: PageQuery['market']['page']['cmsItemPageMetadata']
}

const Seo: React.FC<SeoProps> = ({ pageMetadata }) => {
  const { t } = useTranslation()
  const isDefaultLanguage = useIsDefaultLanguage()

  // default to app title / description if no metadata set in CMS
  const defaultMetadata = {
    metaTitle: t(
      'app.meta.title1',
      'Personalized video messages from celebrities - memmo.me',
    ),
    metaDescription: t(
      'app.meta.description1',
      'Get personalized videos from your favourite celebrities. Connect with thousands of stars and share the joy for any occasion. Money-back guarantee and 24h delivery options.',
    ),
  }

  const metadata = { ...defaultMetadata, ...pageMetadata }
  const { metaTitle, metaDescription, metaImage, metaVideo } = metadata

  return (
    <NextSeo
      title={metaTitle}
      description={metaDescription}
      openGraph={{
        title: metaTitle,
        description: metaDescription,
        ...(metaImage?.url && {
          images: [{ url: metaImage.url }],
        }),
        ...(metaVideo?.url && {
          type: 'video.other',
          videos: [
            {
              url: metaVideo.url,
              width: 400,
              height: 532,
            },
          ],
        }),
      }}
      noindex={!isDefaultLanguage}
      nofollow={!isDefaultLanguage}
    />
  )
}

export default Seo
